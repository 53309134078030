import { search } from 'superagent';
import VenueSearchMap from './venue-search-map.service';

let ctrl;

/**
 * NOTE: This component has to use some global variables and jQuery :(
 */
class VenueSearchMapComponent {
  constructor(private VenueSearchFiltersService, private VenueSearchMapService: VenueSearchMap, ENUMS, $element, private unwrapError) {
    'ngInject';
    ctrl = this;
    ctrl.ENUMS = ENUMS;
    ctrl.$element = $element;

  }


  $onInit() {
    const locationMapConfig = ctrl.search.aliases[0].map;
    const defaults = {
      mapTypeControl: false,
      mapTypeId: 'roadmap',
      panControl: false,
      scrollwheel: false,
      streetViewControl: false,
      zoom: 12,
      zoomControl: true,
      gestureHandling: 'greedy'
    };
    ctrl.googleMapsConfigs = Object.assign(defaults, locationMapConfig);
  }

  $onDestroy() {
    this.VenueSearchMapService.clean();
  }

  handleMapSearchToggle = () => {
    if (ctrl.search.bounds.allowMapSearch) {
      ctrl.search.applyFilters()
        .then(() => this.VenueSearchMapService.drawMarkers(ctrl.search.venues))
        .catch(error => this.unwrapError(error));
    }
  }

  boundsChangeHandler = ({ bottomLeft, upperRight, isMapDraggedOrZoomed }) => {
    ctrl.search.setBounds({ upperRight, bottomLeft });

    if (!ctrl.search.bounds.allowMapSearch) {
      return;
    }
    const isNeighbourhoodFilterActive = this.VenueSearchFiltersService.enabledFilters.some(filter => filter.path === 'data.address.neighborhood');
    if (ctrl.search.setZoomFlag && isMapDraggedOrZoomed && isNeighbourhoodFilterActive) {
      this.VenueSearchFiltersService.clearFilter('data.address.neighborhood', 'venue');
    }
    if (ctrl.search.setZoomFlag) {
      ctrl.search.applyFilters()
      .then(() => {
          if (ctrl.search.venues.length > 1) {
            this.VenueSearchMapService.drawMarkers(ctrl.search.venues);
          }
        })
      .catch(error => this.unwrapError(error));
    } else {
      ctrl.search.setZoomFlag = true;
    }
  }

  $postLink() {
    this.VenueSearchMapService.init($(ctrl.$element.find('.search-map-content'))[0], ctrl.googleMapsConfigs, ctrl.search.viewType, ctrl.scrollCards);
    this.VenueSearchMapService.drawMarkers(ctrl.search.venues);
    this.VenueSearchMapService.addBoundsChangeListener(this.boundsChangeHandler);
  }
}

export default {
  template: require('./venue-search-map.jade'),
  bindToController: true,
  bindings: {
    search: '<',
    showMap: '<',
    onSelectWindow: '&',
    scrollCards: '<'
  },
  controller: VenueSearchMapComponent
};
