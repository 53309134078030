import get from 'lodash/get';
import { RawList } from 'spc/lib/database/types/list';
import getListOwnerFullName from '../list-service';
import { ApiService } from 'spc/shared/api/api.service';

class ListCardController {
  userId: string;
  lists: RawList[];
  archivedLists: RawList[];
  hasArchived: boolean;
  totalLists: number;
  documentsPerPage: number = 12;
  currentPage: number;
  getLists: () => RawList[];
  loading: boolean;

  constructor(
    private $clickOk,
    private unwrapError,
    private $cloudinary,
    private $api: ApiService,
    private createDuplicateListModal,
  ) {
    'ngInject';
  }

  $onInit() {
    this.getLists();
  }

  isArchived() {
    return this.hasArchived;
  }

  getListLength(savedVenues) {
    const visibleVenues = savedVenues.filter(s => s.venue);
    return visibleVenues.length;
  }

  getCollaborators(list) {
    return list.users.length - 1;
  }

  createdBy(list) {
    return getListOwnerFullName(list);
  }

  archiveList (list) {
    list.isVisible = false;
    return this.$api.Lists.toggleListVisibility(list)
      .then(() => {
        this.getLists();
      })
      .catch(error => this.unwrapError(error));
  }

  unarchiveList (list) {
    return this.$api.Lists.toggleListVisibility(list)
      .then(() => {
        this.lists = this.lists.filter(archiveList => archiveList._id !== list._id);
      })
      .catch(error => this.unwrapError(error));
  }


  isPrimaryClient (list) {
    const primaryUser = list.users.filter((user) => {
      return ( user.client._id === this.userId && user.isPrimary );
    });
    if (primaryUser.length) {
      return true;
    }
    return false;
  }

  deleteList (listId)  {
    const showCancel = true;
    return this.$clickOk('Are you sure you want to delete this list?', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        return this.$api.Lists.delete(listId)
          .then(() => {
            this.getLists();
          });
      })
      .catch(error => this.unwrapError(error));
  }

  openCreateDuplicateListModal(list) {
    return this.createDuplicateListModal(list)
      .then(() => window.location.reload())
      .catch(error => this.unwrapError(error));
  }

  removeUserFromList (listId) {
    const showCancel = true;
    const message = 'Leaving this list will remove you as a collaborator and also remove it from your dashboard.';
    return this.$clickOk(message, showCancel)
          .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
          return this.$api.Lists.removeUser (this.userId, listId)
          .then(() => {
            this.getLists();
          });
      })
      .catch(error => this.unwrapError(error));
  }
}

export const ListCardComponent = {
  template: require('./list-card.component.jade'),
  controller: ListCardController,
  bindings: {
    lists: '=',
    archivedLists: '=',
    userId: '<',
    hasArchived: '<',
    getLists: '=',
    totalLists: '<',
    totalArchivedList: '<',
    currentPage: '<',
    loading: '='
  }
};
