import _ from 'lodash';
import { get } from 'lodash';
import price from 'common/dist/price';
import { UserService } from 'spc/services/user.service';
import { fbMinRangeDollars, roomFeeRange } from 'common/dist/price';
import commafy from 'commafy';
import moment from 'moment';
import { timeAsInteger } from 'common/dist/time';

// Types
import VenueSearchMap from './venue-search-map.service';
import { ResultCardService } from './result-card.service';
import { VenueViewUiData } from 'spc/venue-view/venue-view-ui.service';

const DEFAULT_DURATION = 120; // minutes

/**
 * Presentational component to display space-related information as a search result card
 */
let ctrl;
class SpaceResultForShellCardComponent {
  space;
  venue;
  uiData: VenueViewUiData;
  user: UserService;
  addSpace: (space) => void;
  selectedSpaces;
  calledForSelected;
  spaceAlreadyAdded;
  flickityOptions: any;

  constructor($cloudinary, private resultCardService: ResultCardService, private VenueSearchMapService: VenueSearchMap, private photoDialog, private $user: UserService, private cspVideoPlayer, private AvailabilityFactory) {
    'ngInject';
    ctrl = this;
    ctrl.$cloudinary = $cloudinary;
  }

  $onInit = () => {
    this.uiData = {};
    this.user = this.$user;
    this.flickityOptions = {
      initialIndex: 0,
      wrapAround: true,
      cellAlign: 'left',
      pageDots: false,
    };
  }

  /**
   * Gets the cloudinary url for a space's cover index photo, and defaults the image type to ipad
   *
   * @public
   * @param {Object} space
   * @param {String} imgType, defaults to `ipad`
   * @return {String}
   */

  selectSpace () {
    this.space['venue'] = { name: get(this.venue, 'data.name') || get(this.space, 'venue.name'), id: get(this.venue, '_id') || get(this.space, 'venue.id') };
    this.addSpace(this.space);
  }

  showVideoIcon(venue) {
    const isPremiumUser = this.user.isPremiumMember;
    let videoIcon = false;
    const hasVideos = venue.data.videos;
    if (hasVideos.length) {
      hasVideos.forEach((video) => {
        if (isPremiumUser || !isPremiumUser && video.title === 'Teaser') {
          videoIcon = true;
        }
      });
    }
    return videoIcon;
  }

  async getVideoPlayer(videos, venueName, venueAddress, venueSlug) {
    const isPremiumUser = await this.user.isPremiumMember();
    if (!videos.length) {
      return;
    }
    const teaserVideo = videos.find(vid => vid.title === 'Teaser' ? vid : null);
    const fullVideo = videos.find(vid => vid.title === 'Full' ? vid : null);
    if (isPremiumUser) {
      const video = fullVideo || teaserVideo;
      return this.cspVideoPlayer({ video: video, venueAddress: venueAddress, venueName: venueName, venueSlug: venueSlug });
    }
    else if (!isPremiumUser && !_.isEmpty(teaserVideo)) {
      return this.cspVideoPlayer({ video: teaserVideo, venueAddress: venueAddress, venueName: venueName, venueSlug: venueSlug });
    }

    else {
      return;
    }
  }

  displayMin(space) {
    const fnbMin = fbMinRangeDollars(space);
    return fnbMin === 'Inquire to confirm minimum spend' || fnbMin === 'No minimum spend' ? 'TBC' : fnbMin || 'TBC';
  }

  displayRoomFee (space) {
    return roomFeeRange(space) || 'TBC';
  }

  openPhotoDialog = ({ photos, initialPhoto }): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  srcSet(space) {
    if (!get(space, 'data.photos.length')) {
      return '';
    }
    return ctrl.$cloudinary.srcSet(space.data.photos[space.data.coverIndex].url);
  }

  handleHover = (venue) => {
    this.VenueSearchMapService.triggerMarker(venue._id);
  }

  handleHoverOut = (venue) => {
    this.VenueSearchMapService.resetIcon(venue._id);
  }
}

export default {
  bindToController: true,
  bindings: {
    space: '<',
    venue: '<',
    calledForSelected: '<',
    spaceAlreadyAdded: '<',
    selectedSpaces: '<',
    addSpace: '&',
  },
  template: require('./space-result-for-shell-card.jade'),
  controller: SpaceResultForShellCardComponent
};
